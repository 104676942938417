var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "错误收集",
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.cancelModal, ok: _vm.confirmModal },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "error-modal" },
        [
          _c(
            "a-checkbox-group",
            {
              on: { change: _vm.handleChangeCheckBox },
              model: {
                value: _vm.checkBoxFrontBack,
                callback: function($$v) {
                  _vm.checkBoxFrontBack = $$v
                },
                expression: "checkBoxFrontBack"
              }
            },
            [
              _c("a-checkbox", { attrs: { value: 0 } }, [_vm._v("正面")]),
              _c("a-checkbox", { attrs: { value: 1 } }, [_vm._v("背面")])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticStyle: { height: "20px" } }),
      _c(
        "div",
        { staticClass: "error-modal" },
        [
          _c(
            "a-radio-group",
            {
              on: { change: _vm.handleChangeRadio },
              model: {
                value: _vm.errorTypeId,
                callback: function($$v) {
                  _vm.errorTypeId = $$v
                },
                expression: "errorTypeId"
              }
            },
            _vm._l(_vm.errorTypeList, function(item) {
              return _c(
                "a-radio",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.value))]
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }