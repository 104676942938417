<template>
  <!-- 推送样本库 -->
  <a-modal
    v-model="pushModal"
    :width='800'
    title="审核推荐"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-radio-group v-model="section" @change="changeRadio">
          <a-radio :value="1">最新好版</a-radio>
          <a-radio :value="2">最新对版</a-radio>
          <a-radio :value="3">公开错误对版</a-radio>
          <a-radio :value="4">对私标记</a-radio>
        </a-radio-group>
        <br/>
        <br/>
        <a-input v-model="errorMsg" size="large" placeholder="标注错误原因" v-if="section == 3"/>
        <div v-if="!showSelectRefuseAuto && section === 4" class="select-box">
          <a-select
              allowClear
              v-model="errorMsgText"
              style="width:600px;"
              placeholder='请选择原因'
              @change="handleChangeSelectRefuseType"
          >
            <a-select-option
                v-for="item in errMsgList"
                :key="item.id"
                :value='item.id'
            >
              <span>{{ item.value }}</span>
              <a-icon v-if="item.icon" :type="item.icon" style="color: #2d8cf0;margin-left: 5px;"/>
            </a-select-option>
          </a-select>
          <a-tooltip placement="top">
            <template slot="title">
              <span>二次编辑当前原因</span>
            </template>
            <a-icon
                @click="handleDoubleEditorCurrentType"
                type="edit"
                style="color: red;margin-left: 20px;cursor: pointer;font-size: 24px"
            />
          </a-tooltip>
        </div>
        <div
            v-if="section === 4 && showSelectRefuseAuto"
            style="width: 100%;display: flex;justify-content: flex-start;align-items: center"
        >
          <a-input
              allowClear
              v-model="errorMsgText"
              style="width: 600px"
              placeholder='输入反驳原因'
          ></a-input>
          <a-tooltip placement="top">
            <template slot="title">
              <span>关闭自定义输入原因</span>
            </template>
            <a-icon
                @click="handleCloseAuto"
                type="close-circle"
                style="margin-left: 20px;font-size: 24px;color: red;cursor: pointer"
            />
          </a-tooltip>
        </div>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils"
import {errMsgList} from "@/views/cmsPage/versionManage/_data"
export default {
  data() {
    return {
      errMsgList,
      untils,
      pushModal: false,
      curItem: {},
      sectionValue: "",
      section: '',
      errorMsg: undefined,
      errorMsgText: undefined,
      showSelectRefuseAuto: false,
      errorMsgTextById: ''
    };
  },
  methods: {
    /** 二次编辑当前选择的原因 */
    handleDoubleEditorCurrentType() {
      if (this.errorMsgText) {
        if (this.errorMsgText === 'auto') {
          this.errorMsgText = undefined
        } else {
          this.errorMsgText = this.errMsgList.find(el => el.id === this.errorMsgTextById).value
        }
        this.showSelectRefuseAuto = true
      } else {
        this.$message.warn('请选择原因')
      }
    },
    /** 切换驳回原因 */
    handleChangeSelectRefuseType(e) {
      if (e === 'auto') {
        this.errorMsgText = undefined
        this.showSelectRefuseAuto = true
      }  else {
        this.errorMsgTextById = e
        this.errorMsgText = this.errMsgList.find(el => el.id === this.errorMsgText).value
      }
    },
    /** 关闭自定义输入驳回原因 */
    handleCloseAuto() {
      this.showSelectRefuseAuto = false
    },
    show(data) {
      this.curItem = JSON.parse(JSON.stringify(data));
      this.setDefault();
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      this.errorMsg = this.curItem.tips;
      
      this.sectionValue = this.curItem.markSectionType;

      if(this.sectionValue == "good"){
        this.section = 1;
      }else if(this.sectionValue == "latest"){
        this.section = 2;
      }else if(this.sectionValue == "error"){
        this.section = 3;
      }else if(this.sectionValue == "private"){
        this.section = 4;
      }
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
      this.sectionValue = "";
      this.section = '';
      // this.errorMsg = "";
    },
    // 确认
    async confirmPushModal() {
      const {id} = this.curItem;
      if (this.section === 4) {
        if (this.errorMsgText) {
          this.errorMsg = this.errorMsgText
        } else {
          return this.$message.warn('请选择原因')
        }
      }

      // const {id, coinKindId, coinKindItemId, versionId } = this.curItem;

      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/reviewToSquare",
        {
          params: {
            id: id,
            markSectionType: this.sectionValue,
            errorTips: this.errorMsg,
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success", {
        errText: this.errorMsg,
        id: id
      });
    },

    changeRadio(e) {
      // console.log('111111111');
      // console.log('radio checked', e.target.value);
      var indexValue =  e.target.value;
      if(indexValue == 1){
        this.sectionValue = "good";
      }else if(indexValue == 2){
        this.sectionValue = "latest";
      }else if(indexValue == 3){
        this.sectionValue = "error";
      }else if(indexValue == 4){
        this.sectionValue = "private";
      }
      // console.log(this.sectionValue);
      // console.log(this.errorMsg);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>