var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "审核推荐",
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.cancelPushModal, ok: _vm.confirmPushModal },
      model: {
        value: _vm.pushModal,
        callback: function($$v) {
          _vm.pushModal = $$v
        },
        expression: "pushModal"
      }
    },
    [
      _c("div", { staticClass: "push-modal" }, [
        _c(
          "div",
          [
            _c(
              "a-radio-group",
              {
                on: { change: _vm.changeRadio },
                model: {
                  value: _vm.section,
                  callback: function($$v) {
                    _vm.section = $$v
                  },
                  expression: "section"
                }
              },
              [
                _c("a-radio", { attrs: { value: 1 } }, [_vm._v("最新好版")]),
                _c("a-radio", { attrs: { value: 2 } }, [_vm._v("最新对版")]),
                _c("a-radio", { attrs: { value: 3 } }, [
                  _vm._v("公开错误对版")
                ]),
                _c("a-radio", { attrs: { value: 4 } }, [_vm._v("对私标记")])
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _vm.section == 3
              ? _c("a-input", {
                  attrs: { size: "large", placeholder: "标注错误原因" },
                  model: {
                    value: _vm.errorMsg,
                    callback: function($$v) {
                      _vm.errorMsg = $$v
                    },
                    expression: "errorMsg"
                  }
                })
              : _vm._e(),
            !_vm.showSelectRefuseAuto && _vm.section === 4
              ? _c(
                  "div",
                  { staticClass: "select-box" },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "600px" },
                        attrs: { allowClear: "", placeholder: "请选择原因" },
                        on: { change: _vm.handleChangeSelectRefuseType },
                        model: {
                          value: _vm.errorMsgText,
                          callback: function($$v) {
                            _vm.errorMsgText = $$v
                          },
                          expression: "errorMsgText"
                        }
                      },
                      _vm._l(_vm.errMsgList, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _c("span", [_vm._v(_vm._s(item.value))]),
                            item.icon
                              ? _c("a-icon", {
                                  staticStyle: {
                                    color: "#2d8cf0",
                                    "margin-left": "5px"
                                  },
                                  attrs: { type: item.icon }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "top" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v("二次编辑当前原因")])
                        ]),
                        _c("a-icon", {
                          staticStyle: {
                            color: "red",
                            "margin-left": "20px",
                            cursor: "pointer",
                            "font-size": "24px"
                          },
                          attrs: { type: "edit" },
                          on: { click: _vm.handleDoubleEditorCurrentType }
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.section === 4 && _vm.showSelectRefuseAuto
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "flex-start",
                      "align-items": "center"
                    }
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "600px" },
                      attrs: { allowClear: "", placeholder: "输入反驳原因" },
                      model: {
                        value: _vm.errorMsgText,
                        callback: function($$v) {
                          _vm.errorMsgText = $$v
                        },
                        expression: "errorMsgText"
                      }
                    }),
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "top" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v("关闭自定义输入原因")])
                        ]),
                        _c("a-icon", {
                          staticStyle: {
                            "margin-left": "20px",
                            "font-size": "24px",
                            color: "red",
                            cursor: "pointer"
                          },
                          attrs: { type: "close-circle" },
                          on: { click: _vm.handleCloseAuto }
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }