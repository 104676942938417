<template>
  <div>
    <a-modal
        :width="600"
        v-model="show"
        title="开元会昌背面识别"
        @ok="handleCancelPrint"
        @cancel="handleCancelPrint"
    >
      <template #footer>
        <div></div>
      </template>
      <div class="kaiyuan-content">
        <div class="font-size-20 font-weight-bold">识别率：<span class="mt-10 color-green">{{ popupData.percentage }}</span></div>
        <div class="mt-10 font-size-20 font-weight-bold">背字：<span class="mt-10 color-red">{{ popupData.kaiyuanBackRecoName }}</span></div>
        <div class="cut-img mt-10 font-size-20 font-weight-bold ">
          裁剪后的背图：
          <div class="mt-10 w-100-w">
            <img :src="popupData.cutBackImage" alt="" @click="handleShowBigImg(popupData.cutBackImage)">
          </div>
        </div>
        <!--   选择背字   -->
        <div class="bei-text-list mt-10" >
          <div
              v-for="tag in kaiYuanCoinBackTextList"
              :key="tag.id"
              :class="tag.id === specialItemId ? 'active-tag' : ''"
              class="mr-10 mb-10 cur-pot font-size-32 p-10 bei-text-tag"
              @click="handleSelectedTag(tag.id)"
          >{{ tag.name }}</div>
        </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定要收集吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="getKaiYuanBackImgCollect"
          >
            <a-button class="ml-30" size="small" type="danger">推送到开元背图</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getKaiYuanBackImgCollectApi} from "@/views/cmsPage/versionManage/duibanrecord/_apis"

export default {
  data() {
    return {
      show: false,
      popupData: '',
      backUrl: '',
      kaiYuanCoinBackTextList: [],
      specialItemId: '',
    };
  },
  methods: {
    async showPopup(data, backUrl) {
      this.backUrl = backUrl
      this.popupData = data
      await this.getKaiYuanBackText()
      this.show = true
    },
    /** 获取开元背字 */
    async getKaiYuanBackText() {
      await this.axios('/dq_admin/duibanKaiyuanSpecialItemData/listItems', {
        params: { type: 1 }
      }).then(res=>{
        if(res.status === '200'){
          this.kaiYuanCoinBackTextList = res.data
        }
      })
    },
    /** 推送数据 */
    async getKaiYuanBackImgCollect() {
      if (!this.specialItemId) return this.$message.info('请选择要推送到的背字')
      this.$loading.show()
      const res = await getKaiYuanBackImgCollectApi({
        cutBackImage: this.popupData.cutBackImage,
        backImage: this.backUrl,
        specialItemId: this.specialItemId
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('收集成功')
      this.handleCancelPrint()
    },
    /** 取消 */
    handleCancelPrint() {
      this.show = false
      this.popupData = ''
      this.backUrl = ''
      this.specialItemId = ''
    },
    /** 显示大图 */
    handleShowBigImg(img) {
      this.$previewImg({
        list: [{img_url: img}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 选择tag */
    handleSelectedTag(tagId) {
      this.specialItemId = tagId
    },
  },
};
</script>

<style lang="scss" scoped>
.cut-img {
  width: 100%;
  img {
    width: 200px;
  }
}
.kaiyuan-content {
  width: 100%;
}
.bei-text-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.bei-text-tag {
  padding: 10px 20px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.active-tag {
  border: 1px solid #2d8cf0;
  background-color: green;
}
</style>
