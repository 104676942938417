var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 1200, title: "机器对比" },
          on: { ok: _vm.handleCancelPrint, cancel: _vm.handleCancelPrint },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [_c("div")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "content-version-modal" },
            _vm._l(_vm.popupData, function(item, index) {
              return _c(
                "div",
                { key: item.modelVersion, staticClass: "version-item" },
                [
                  _c("div", { staticClass: "item-title mt-20" }, [
                    _vm._v("模型名称："),
                    _c("span", [_vm._v(_vm._s(item.modelVersion))])
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-son-list" },
                    _vm._l(item.machineList, function(el, ind) {
                      return _c(
                        "a-tag",
                        {
                          key: ind,
                          staticClass: "mr-5 mb-5 font-size-14",
                          attrs: { color: "blue" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(el.itemName + "·" + el.versionName) +
                              " "
                          ),
                          _c(
                            "span",
                            { staticClass: "font-weight-bold color-dq-blue" },
                            [_vm._v("（" + _vm._s(el.distance) + "）")]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }