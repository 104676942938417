<template>
  <div>
    <a-modal
        :width="1200"
        v-model="show"
        title="机器对比"
        @ok="handleCancelPrint"
        @cancel="handleCancelPrint"
    >
      <template #footer>
        <div></div>
      </template>
      <div class="content-version-modal">
        <div
            v-for="(item, index) in popupData"
            :key="item.modelVersion"
            class="version-item"
        >
          <div class="item-title mt-20">模型名称：<span>{{ item.modelVersion }}</span></div>
          <div class="item-son-list">
            <a-tag
                class="mr-5 mb-5 font-size-14"
                v-for="(el, ind) in item.machineList"
                :key="ind"
                :color="'blue'"
            >
              {{ el.itemName + '·' + el.versionName }}
              <span class="font-weight-bold color-dq-blue">（{{ el.distance }}）</span>
            </a-tag>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {versionModalApi} from "@/views/cmsPage/versionManage/duibanrecord/_apis"

export default {
  data() {
    return {
      show: false,
      popupData: '',
      recordId: '',
      colorList: {
        0: 'blue',
        1: 'green',
        2: 'cyan',
        3: 'purple',
        4: 'orange'
      }
    };
  },
  methods: {
    async showPopup(data) {
      this.recordId = data.id;
      await this.getVersionModalList()
      this.show = true
    },
    /** 获取模型对比列表 */
    async getVersionModalList() {
      this.$loading.show()
      const res = await versionModalApi(this.recordId)
      this.$loading.hide()
      if (res.status !== '200') return
      this.popupData = res.data
      console.log(this.popupData)
    },
    /** 取消 */
    handleCancelPrint() {
      this.show = false
      this.popupData = ''
      this.recordId = ''
    },
  },
};
</script>

<style lang="scss" scoped>
.item-son-list {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.item-title {
  font-size: 16px;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 23px;
    color: black;
    font-weight: bold;
  }
}
.content-version-modal {
  width: 100%;
}
</style>
