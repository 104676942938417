<template>
  <a-modal
      v-model="showPopup"
      title="GUP/CPU对版消耗比对"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancelOrConfirm"
      @ok="handleCancelOrConfirm"
  >
    <div class="popup-content">
      <div class="yesterday-beforeyesterday-data-list pr-20">
        <div class="data-title-btn data-title-btn-right">
          <span class="data-title">{{ leftTitle }}</span>
          <span class="data-title ml-10">{{ leftCoin }}：识别钱币</span>
          <span class="data-title ml-10">{{ leftPercentage }}：检测概率</span>
        </div>
        <div class="data-list data-list-right">
          <div class="data-item" v-for="(item, index) in leftData" :key="index">
            <div class="data-item-num">{{ item.score }}：</div>
            <div class="data-item-title color-gray">{{ item.versionName }}</div>
          </div>
        </div>
      </div>
      <div class="yesterday-beforeyesterday-data-list pl-20">
        <div class="data-title-btn data-title-btn-left">
          <span class="data-title">{{ rightTitle }}</span>
          <span class="data-title ml-10">识别钱币：{{ rightCoin }}</span>
          <span class="data-title ml-10">检测概率：{{ rightPercentage }}</span>
        </div>
        <div class="data-list data-list-left">
          <div class="data-item" v-for="(item, index) in rightData" :key="index">
            <div class="data-item-title color-gray">{{ item.versionName }}：</div>
            <div class="data-item-num">{{ item.score }}</div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>

import {gpuOrCpuVersionApi} from "@/views/cmsPage/versionManage/duibanrecord/_apis"

export default {
  props: ['rateType'],
  computed: {
    computedDataBigOrSmall() {
      return (index) => {
        const leftDataNum = this.leftData[index].score
        const rightDataNum = this.rightData[index].score
        if (leftDataNum > rightDataNum) {
          return 'up'
        } else if (leftDataNum < rightDataNum) {
          return 'down'
        } else {
          return 'equal'
        }
      }
    }
  },
  data() {
    return {
      recordId: '',
      showPopup: false,
      leftData: '',
      rightData: '',
      leftTitle: 'CPU',
      leftCoin: '',
      leftPercentage: '',
      rightTitle: 'GPU',
      rightCoin: '',
      rightPercentage: '',
      textMapColor: {
        up: {
          color: 'color-green',
          iconColor: 'color-green',
          icon: 'rise'
        },
        down: {
          color: 'color-red',
          iconColor: 'color-red',
          icon: 'fall'
        },
        equal: {
          color: '',
          iconColor: 'color-gray',
          icon: ''
        },
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    async show(id) {
      this.recordId = id
      await this.getGPUOrCPUVersion()
      this.showPopup = true
    },
    /** 获取GPU，CPU消耗对比 */
    async getGPUOrCPUVersion() {
      this.$loading.show()
      const res = await gpuOrCpuVersionApi(this.recordId)
      this.$loading.hide()
      if (res.status !== '200') return
      this.leftTitle = res.data[0].title
      this.leftCoin = res.data[0].coin
      this.leftPercentage = res.data[0].percentage
      this.rightTitle = res.data[1].title
      this.rightCoin = res.data[1].coin
      this.rightPercentage = res.data[1].percentage
      if (res.data && res.data[0].versionList.length) {
        this.leftData = res.data[0].versionList
      }
      if (res.data && res.data[1].versionList.length) {

        this.rightData = res.data[1].versionList
      }
    },
    handleCancelOrConfirm() {
      this.showPopup = false
    }
  }
}
</script>
<style scoped lang="scss">
.data-list {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .data-item {
    margin-bottom: 20px;
    display: flex;
    font-weight: bold;
    font-size: 20px;
    .data-item-num {
      color: #0f0f0f;
    }
  }
}
.data-list-right {
  align-items: flex-end;
}
.data-list-left {
  align-items: flex-start;
}
.yesterday-beforeyesterday-data-list {
  width: 100%;
  .data-title-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .data-title {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .data-title-btn-left {
    align-items: flex-end;
  }
  .data-title-btn-right {
    align-items: flex-start;
  }
}
.popup-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.pr-20 {
  padding-right: 20px;
  border-right: 1px solid gray;
}
.pl-20 {
  padding-left: 20px;
  border-left: 1px solid gray;
}
</style>