var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "GUP/CPU对版消耗比对",
        width: 800,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancelOrConfirm, ok: _vm.handleCancelOrConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", { staticClass: "popup-content" }, [
        _c(
          "div",
          { staticClass: "yesterday-beforeyesterday-data-list pr-20" },
          [
            _c("div", { staticClass: "data-title-btn data-title-btn-right" }, [
              _c("span", { staticClass: "data-title" }, [
                _vm._v(_vm._s(_vm.leftTitle))
              ]),
              _c("span", { staticClass: "data-title ml-10" }, [
                _vm._v(_vm._s(_vm.leftCoin) + "：识别钱币")
              ]),
              _c("span", { staticClass: "data-title ml-10" }, [
                _vm._v(_vm._s(_vm.leftPercentage) + "：检测概率")
              ])
            ]),
            _c(
              "div",
              { staticClass: "data-list data-list-right" },
              _vm._l(_vm.leftData, function(item, index) {
                return _c("div", { key: index, staticClass: "data-item" }, [
                  _c("div", { staticClass: "data-item-num" }, [
                    _vm._v(_vm._s(item.score) + "：")
                  ]),
                  _c("div", { staticClass: "data-item-title color-gray" }, [
                    _vm._v(_vm._s(item.versionName))
                  ])
                ])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "yesterday-beforeyesterday-data-list pl-20" },
          [
            _c("div", { staticClass: "data-title-btn data-title-btn-left" }, [
              _c("span", { staticClass: "data-title" }, [
                _vm._v(_vm._s(_vm.rightTitle))
              ]),
              _c("span", { staticClass: "data-title ml-10" }, [
                _vm._v("识别钱币：" + _vm._s(_vm.rightCoin))
              ]),
              _c("span", { staticClass: "data-title ml-10" }, [
                _vm._v("检测概率：" + _vm._s(_vm.rightPercentage))
              ])
            ]),
            _c(
              "div",
              { staticClass: "data-list data-list-left" },
              _vm._l(_vm.rightData, function(item, index) {
                return _c("div", { key: index, staticClass: "data-item" }, [
                  _c("div", { staticClass: "data-item-title color-gray" }, [
                    _vm._v(_vm._s(item.versionName) + "：")
                  ]),
                  _c("div", { staticClass: "data-item-num" }, [
                    _vm._v(_vm._s(item.score))
                  ])
                ])
              }),
              0
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }