import { render, staticRenderFns } from "./ModalErrorCollect.vue?vue&type=template&id=3434e0cb&scoped=true&"
import script from "./ModalErrorCollect.vue?vue&type=script&lang=js&"
export * from "./ModalErrorCollect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3434e0cb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhangshilei/Desktop/pro/dq-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3434e0cb')) {
      api.createRecord('3434e0cb', component.options)
    } else {
      api.reload('3434e0cb', component.options)
    }
    module.hot.accept("./ModalErrorCollect.vue?vue&type=template&id=3434e0cb&scoped=true&", function () {
      api.rerender('3434e0cb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cmsPage/versionManage/duibanrecord/ModalErrorCollect.vue"
export default component.exports