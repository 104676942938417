var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 600, title: "开元会昌背面识别" },
          on: { ok: _vm.handleCancelPrint, cancel: _vm.handleCancelPrint },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [_c("div")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("div", { staticClass: "kaiyuan-content" }, [
            _c("div", { staticClass: "font-size-20 font-weight-bold" }, [
              _vm._v("识别率："),
              _c("span", { staticClass: "mt-10 color-green" }, [
                _vm._v(_vm._s(_vm.popupData.percentage))
              ])
            ]),
            _c("div", { staticClass: "mt-10 font-size-20 font-weight-bold" }, [
              _vm._v("背字："),
              _c("span", { staticClass: "mt-10 color-red" }, [
                _vm._v(_vm._s(_vm.popupData.kaiyuanBackRecoName))
              ])
            ]),
            _c(
              "div",
              { staticClass: "cut-img mt-10 font-size-20 font-weight-bold " },
              [
                _vm._v(" 裁剪后的背图： "),
                _c("div", { staticClass: "mt-10 w-100-w" }, [
                  _c("img", {
                    attrs: { src: _vm.popupData.cutBackImage, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(_vm.popupData.cutBackImage)
                      }
                    }
                  })
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "bei-text-list mt-10" },
              _vm._l(_vm.kaiYuanCoinBackTextList, function(tag) {
                return _c(
                  "div",
                  {
                    key: tag.id,
                    staticClass:
                      "mr-10 mb-10 cur-pot font-size-32 p-10 bei-text-tag",
                    class: tag.id === _vm.specialItemId ? "active-tag" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleSelectedTag(tag.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(tag.name))]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "确定要收集吗？",
                      "ok-text": "确认",
                      "cancel-text": "取消"
                    },
                    on: { confirm: _vm.getKaiYuanBackImgCollect }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-30",
                        attrs: { size: "small", type: "danger" }
                      },
                      [_vm._v("推送到开元背图")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }