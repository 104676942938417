import { render, staticRenderFns } from "./ModalReviewStore.vue?vue&type=template&id=1e982330&scoped=true&"
import script from "./ModalReviewStore.vue?vue&type=script&lang=js&"
export * from "./ModalReviewStore.vue?vue&type=script&lang=js&"
import style0 from "./ModalReviewStore.vue?vue&type=style&index=0&id=1e982330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e982330",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhangshilei/Desktop/pro/dq-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e982330')) {
      api.createRecord('1e982330', component.options)
    } else {
      api.reload('1e982330', component.options)
    }
    module.hot.accept("./ModalReviewStore.vue?vue&type=template&id=1e982330&scoped=true&", function () {
      api.rerender('1e982330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cmsPage/versionManage/duibanrecord/ModalReviewStore.vue"
export default component.exports