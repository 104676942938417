<template>
  <!-- 推送样本库 -->
  <a-modal
      v-model="showModal"
      :width='800'
      title="错误收集"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelModal"
      @ok="confirmModal"
  >

    <div class="error-modal">
      <a-checkbox-group v-model="checkBoxFrontBack" @change="handleChangeCheckBox">
        <a-checkbox :value="0">正面</a-checkbox>
        <a-checkbox :value="1">背面</a-checkbox>
      </a-checkbox-group>
    </div>

    <div style="height:20px;"></div>
  
    <div class="error-modal">
      <a-radio-group v-model="errorTypeId" @change="handleChangeRadio">
        <a-radio v-for="item in errorTypeList" :key="item.id"  :value="item.id">{{ item.value }}</a-radio>
      </a-radio-group>
    </div>

  </a-modal>
</template>

<script>

export default {
  data() {
    return {
      showModal: false,
      curItem: {},
      errorTypeId: undefined,
      errorTypeList: [
        {value: '没检测到钱币', id: 0},
        {value: '币种正面没检测到文字', id: 1},
        {value: '裁剪错误', id: 10},
        {value: '裁剪边界值问题', id: 20},
        {value: '纹路斑点', id: 30},
        {value: '塑壳反光', id: 40},
        {value: '水渍反光', id: 50}
      ],
      checkBoxFrontBack: []
      // errorType: {
      //   1: 'coinTrain',
      //   2: 'coinCut',
      //   3: 'coinCutBoundary'
      // }
    };
  },
  methods: {
    handleChangeCheckBox(event) {
      this.checkBoxFrontBack = event
    },
    /** 改变多选框 */
    handleChangeRadio(e) {
    },
    /** 改变错误类型 */
    handleChangeErrorType(e) {
    },
    /** 展开弹窗 */
    show(data) {
      this.curItem = JSON.parse(JSON.stringify(data));
      this.showModal = true;
    },
    /** 确定按钮 */
    async confirmModal() {
      // if (!this.errorTypeId) return this.$message.warning('请选择要收集的错误类型')
      const row = this.curItem
      this.$loading.show()
      // const res = await this.axios("/dq_admin/duibanCoinTrain/pushMatchingRecords", {
      //   params: {
      //     recordId: row.id,
      //     type: this.errorTypeList[this.errorTypeId]
      //   }
      // })

      const res = await this.axios.post("/dq_admin/duibanCoinTrain/pushMatchingRecords", 
        {
          recordId: row.id,
          type: this.errorTypeId,
          frontBackList: this.checkBoxFrontBack         
        }
      )

      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success("收集成功");
      if (row.coinTrainStatus === 0) {
        row.coinTrainStatus = 1
      }
      this.$emit("success", row);
      this.cancelModal()
    },
    /** 关闭 */
    cancelModal() {
      this.showModal = false;
      this.curItem = {}
      this.errorTypeId = undefined
      this.checkBoxFrontBack = []
    },
  }
}
</script>

<style lang="scss" scoped>
</style>