var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-version-pages" },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.list,
          rowKey: "id",
          pagination: _vm.pagination,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "idSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.id))]),
                row.sameId
                  ? _c(
                      "div",
                      {
                        staticClass: "text-color-red",
                        on: {
                          click: function($event) {
                            return _vm.handleSameCurrencyList(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.sameId))]
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "coinKindName",
            fn: function(row) {
              return _c(
                "div",
                { class: row.visibleStatus ? "" : "visibleStatus" },
                [
                  _c("div", { staticClass: "coinKindName-flex" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(row.coinKindName + " " + row.coinPercentage) +
                          " "
                      )
                    ]),
                    row.tips
                      ? _c(
                          "div",
                          { staticStyle: { color: "#0f0f0f", width: "100px" } },
                          [_vm._v(" (" + _vm._s(row.tips) + ") ")]
                        )
                      : _vm._e()
                  ]),
                  _c("div", [
                    [0, 1].includes(row.openStatus)
                      ? _c(
                          "span",
                          {
                            style: {
                              color: row.openStatus === 0 ? "blue" : "red"
                            }
                          },
                          [
                            _vm._v(
                              "（" +
                                _vm._s(row.openStatus === 0 ? "开放" : "测试") +
                                "）"
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowChangeBigCoinModel(row)
                                }
                              }
                            },
                            [_vm._v("更改")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                disabled: row.coinTrainStatus === 1,
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handlePushMatchingRecords(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.coinTrainStatus === 1
                                    ? "已收集"
                                    : "识别错误收集"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleExtendQuery(row)
                                }
                              }
                            },
                            [_vm._v("扩展查询")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定重新对版吗？",
                                "ok-text": "确认",
                                "cancel-text": "取消"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleReVersion(row)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function($event) {
                                      row
                                    }
                                  }
                                },
                                [_vm._v("重新对版")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowGpuOrCpuVersion(row)
                                }
                              }
                            },
                            [_vm._v("GPU/CPU消耗对比")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }
          },
          {
            key: "userName",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticClass: "user-name",
                  style: { color: row.attentionStatus === 1 ? "green" : "" },
                  on: {
                    click: function($event) {
                      return _vm.userSearch(row.userId)
                    }
                  }
                },
                [_vm._v(_vm._s(row.userName))]
              )
            }
          },
          {
            key: "confirmVersionNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.confirmVersionName || "-"))]),
                row.confirmVersionName
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.handleResetVersion(row)
                              }
                            }
                          },
                          [_vm._v("重新修改版别")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "images",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "400px",
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: { src: row.frontImage },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(row)
                                        }
                                      }
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: { src: row.backImage },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(row)
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "50px",
                          "max-height": "50px"
                        },
                        attrs: { src: row.frontImage },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  ),
                  row.coinKindId === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowKaiYuanBackImg(row)
                                }
                              }
                            },
                            [_vm._v("开元会昌背面识别")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-center-center mt-10" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleCutImgUrl(row.frontImage)
                            }
                          }
                        },
                        [_vm._v("正")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleCutImgUrl(row.backImage)
                            }
                          }
                        },
                        [_vm._v("反")]
                      )
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "div",
                        { staticClass: "flex-center-center mt-10" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleCutImgUrl(
                                    row.frontImage,
                                    "two"
                                  )
                                }
                              }
                            },
                            [_vm._v("正V2")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "ml-10",
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleCutImgUrl(
                                    row.backImage,
                                    "two"
                                  )
                                }
                              }
                            },
                            [_vm._v("反V2")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-center-center mt-10" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowImgMoreInfo(row.frontImage)
                            }
                          }
                        },
                        [_vm._v("类型检测-正")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowImgMoreInfo(row.backImage)
                            }
                          }
                        },
                        [_vm._v("类型检测-反")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          },
          {
            key: "version",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.feedbackContent
                    ? _c(
                        "div",
                        {
                          staticStyle: { "margin-bottom": "10px", color: "red" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s("反馈内容：" + row.feedbackContent) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("ul", { staticClass: "answers-header" }, [
                    _c("li", [_vm._v("id")]),
                    _c("li", [_vm._v("名称")]),
                    _c("li", [_vm._v("等级")]),
                    _c("li", [_vm._v("尺寸")]),
                    _c("li", [_vm._v("DIS")])
                  ]),
                  _vm._l(row.versionList, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "answers-list" },
                      [
                        _c(
                          "li",
                          { style: { color: item.spTag ? "red" : "" } },
                          [_vm._v(_vm._s(item.versionId))]
                        ),
                        _c(
                          "li",
                          { style: { color: item.spTag ? "red" : "" } },
                          [
                            _vm._v(
                              _vm._s(
                                "" +
                                  (item.sampleSize
                                    ? "【" + item.sampleSize + "】"
                                    : "") +
                                  item.versionName +
                                  (item.tagNames
                                    ? "（" + item.tagNames + "）"
                                    : "")
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          { style: { color: item.spTag ? "red" : "" } },
                          [
                            _vm._v(
                              _vm._s(
                                item.versionLevel ? item.versionLevel : "--"
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          { style: { color: item.spTag ? "red" : "" } },
                          [
                            _vm._v(
                              _vm._s(item.versionSize ? item.versionSize : "--")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          { style: { color: item.spTag ? "red" : "" } },
                          [_vm._v(_vm._s(item.distance))]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.coinKindId === 1
                    ? _c(
                        "a-button",
                        {
                          staticClass: "setup-btn",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowVersionModal(row)
                            }
                          }
                        },
                        [_vm._v("机器对比")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticClass: "setup-btn",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.contrastClick(row, 1, "", "yuanVersion")
                        }
                      }
                    },
                    [_vm._v("原图对版")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "setup-btn",
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: row.confirmStatus == 1
                      },
                      on: {
                        click: function($event) {
                          return _vm.confirmSampleStore(row)
                        }
                      }
                    },
                    [_vm._v("确认到样本库")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      (row.qualityTestingStatus != undefined ||
                        row.qualityTestingStatus != null) &&
                      false
                        ? _c(
                            "div",
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title:
                                      row.qualityTestingStatus === 2
                                        ? "确定要撤销此项吗？"
                                        : "确定要推送此项么？",
                                    "ok-text": "确认",
                                    "cancel-text": "取消",
                                    disabled: row.qualityTestingStatus === 1
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.pushMarkClick(row)
                                    }
                                  }
                                },
                                [
                                  row.qualityTestingStatus === 1
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "setup-btn",
                                          attrs: {
                                            disabled:
                                              row.qualityTestingStatus === 1,
                                            type: "",
                                            size: "small"
                                          }
                                        },
                                        [_vm._v(" 已质检 ")]
                                      )
                                    : _c(
                                        "a-button",
                                        {
                                          staticClass: "setup-btn",
                                          attrs: {
                                            size: "small",
                                            type:
                                              row.qualityTestingStatus === 2
                                                ? ""
                                                : "primary"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.qualityTestingStatus === 2
                                                  ? "撤销质检"
                                                  : "质检"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要将此项推送到问题池子吗？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.handlePushProblem(row)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "setup-btn",
                              attrs: { size: "small", type: "primary" }
                            },
                            [_vm._v("推送到问题列表")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定要删除此项么？",
                        "ok-text": "确认",
                        "cancel-text": "取消"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.remove(row.id)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { size: "small", type: "danger" } },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-dropdown",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "overlay",
                            fn: function() {
                              return [
                                _c(
                                  "a-menu",
                                  [
                                    _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "setup-btn",
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.reviewStore(row)
                                              }
                                            }
                                          },
                                          [_vm._v("审核")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "setup-btn",
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.falseCoinClick(
                                                  row,
                                                  4
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("假币")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "setup-btn",
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.falseCoinClick(
                                                  row,
                                                  6
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("无法辨认")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "setup-btn",
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.falseCoinClick(
                                                  row,
                                                  8
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("加刀")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-left": "5px"
                          },
                          attrs: { size: "small", type: "primary" }
                        },
                        [_vm._v("其它 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          },
          {
            key: "propertySlot",
            fn: function(row) {
              return _c("div", {}, [_c("div", [_vm._v(_vm._s(row.attribute))])])
            }
          }
        ])
      }),
      _c("ViewPicture", {
        ref: "showPicture",
        on: {
          versionSuccess: function($event) {
            return _vm.handleVersionSuccess($event)
          }
        }
      }),
      _c("ModalPushSimStore", {
        ref: "push-m",
        on: { success: _vm.successPushSimStore }
      }),
      _c("ModalReviewStore", {
        ref: "push-v",
        on: {
          success: function($event) {
            return _vm.success($event)
          }
        }
      }),
      _c("ModalChangeCoinStore", {
        ref: "change-coin",
        on: { change: _vm.changeSuccess }
      }),
      _c("ModalErrorCollect", {
        ref: "error-collect",
        on: {
          success: function($event) {
            return _vm.handleErrorCollectSuccess($event)
          }
        }
      }),
      _c("ResetVersion", {
        ref: "resetVersionEl",
        on: {
          versionSuccess: function($event) {
            return _vm.handleVersionSuccess($event)
          }
        }
      }),
      _c("KaiYuanBackImgModelPopup", { ref: "kaiYuanBackImgModelPopupEl" }),
      _c("VersionModalPopup", { ref: "versionModalPopupEl" }),
      _c("VersionGpuOrCpuPopup", { ref: "versionGpuOrCpuPopupEl" }),
      _c("ShowImgScorePopup", { ref: "showImgScorePopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }